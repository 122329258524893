<template>
  <div class="map-wrapper">
    <div class="map-wrapper-backdrop"></div>
    <q-inner-loading style="z-index: 999" :showing="loaderVisible">
      <custom-loader />
    </q-inner-loading>
    <div
      id="styled_map"
      :style="{ width: `${props.mapWidth}px`, height: `${props.mapHeight}px` }"
    ></div>
  </div>
</template>

<script setup lang="ts">
import { YmapStore } from '~/store/Ymap'
import ymStyle from '~/assets/styles/YMstyles/customization.json'
import { getCoordsByString } from '~/helpers/Map/getCoordsByString'

const props = defineProps({
  mapWidth: {
    required: true,
    type: Number,
  },
  mapHeight: {
    required: true,
    type: Number,
  },
  mapCenter: {
    required: false,
    type: String,
  },
})

const storeYmap = YmapStore()
const config = useRuntimeConfig()
const loaderVisible = ref(true) //отображаем загрузчик до момента полного загрузки скрипта карты
const scriptYmap = document.createElement('script')
const mapCenter = computed(() => props.mapCenter)
let mapPreview: any = null
const coordinatesArray = ref([55.753994, 37.622093])

const initMapPreview = async () => {
  const currentCity = await getCoordsByString(mapCenter.value)
  coordinatesArray.value = currentCity
  const ymaps3 = (window as any).ymaps3
  mapPreview = new ymaps3.YMap(document.getElementById('styled_map'), {
    location: {
      center: coordinatesArray.value,
      zoom: 12,
    },
    behaviors: [],
  })
  await mapPreview.addChild(
    new (window as any).ymaps3.YMapDefaultSchemeLayer({
      theme: 'dark',
      customization: ymStyle,
    }),
  )
  loaderVisible.value = false
}

const ymapsReady = new Promise<void>((resolve) => {
  if ((window as any).ymaps3) {
    ;(window as any).ymaps3.ready.then(() => {
      resolve()
    })
  } else {
    scriptYmap.onload = () => {
      if ((window as any).ymaps3) {
        ;(window as any).ymaps3.ready.then(() => {
          resolve()
        })
      }
    }
  }
})

const loadYmap = async () => {
  scriptYmap.src = `https://api-maps.yandex.ru/v3/?apikey=${config.public.env.Y_MAP_API_KEY}&lang=ru_RU`
  scriptYmap.type = 'text/javascript'
  scriptYmap.async = true
  scriptYmap.defer = true
  document.head.appendChild(scriptYmap)
}



onBeforeMount(async () => {
  await loadYmap()
  await ymapsReady
  initMapPreview()
})
</script>

<style scoped lang="scss">
#styled_map {
  overflow: hidden;
}
.map-wrapper {
  position: relative;
  width: fit-content;
  height: fit-content;
  &-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(360deg, rgba(0, 12, 37, 0.6) 0%, rgba(0, 12, 37, 0.15) 100%);
    z-index: 999;
  }
}
</style>
